
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  data() {
    return {
      show: false
    };
  },

  computed: {
    ...mapGetters({
      showNotification: 'notification/show',
      notify: 'notification/notification'
    }),
  },

  watch: {
    showNotification(value) {
      this.show = value;
    }
  },

  methods: {
    alertClass(type: string) {
      return type === 'success' ? 'success' : 'error';
    },

    hide() {
      this.$hideNotification();
    }
  }
});
