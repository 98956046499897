import {App} from 'vue';
import store from "@/store";
import Notification from "@/components/ui/Notification.vue";

declare module 'vue' {
    interface ComponentCustomProperties {
        $notifySuccess: (message: string) => void,
        $notifySuccessLogin: () => void,
        $notifySuccessPasswordResetLink: () => void,
        $notifySuccessRegister: () => void,
        $notifyError: (message: string) => void,
        $notifyFailLogin: () => void,
        $notifyFailResetPassword: (errorCode: string) => void,
        $addToCart: () => void,
        $notifyFailRegister: (errorCode: string) => void,
        $hideNotification: () => void
    }
}

const notifySuccess = (message: string) => {
    store.dispatch('notification/setNotification', {
        type: 'success',
        message
    })
    setTimeout(function () {
        store.dispatch('notification/setNotification', {
            type: 'success',
            message: ''
        })
    }, 2000)
};

const notifySuccessLogin = () => {
    store.dispatch('notification/setNotification', {
        type: 'success',
        message: 'Login success!'
    })
};

const notifySuccessPasswordResetLink = () => {
    store.dispatch('notification/setNotification', {
        type: 'success',
        message: 'Please check you email to reset your password.'
    })
};

const notifySuccessRegister = () => {
    store.dispatch('notification/setNotification', {
        type: 'success',
        message: 'Your account has been registered successfully.'
    })
};

const notifyError = (message: string) => {
    store.dispatch('notification/setNotification', {
        type: 'error',
        message
    })
    setTimeout(function () {
        store.dispatch('notification/setNotification', {
            type: 'error',
            message: ''
        })
    }, 2000)
};

const notifyFailLogin = () => {
    store.dispatch('notification/setNotification', {
        type: 'error',
        message: 'Invalid credentials. Please try again.'
    })
};

const notifyFailRegister = (code: string) => {
    const messages = {
        'auth/email-already-in-use': 'E-mail already in use. Please try another.',
        'auth/invalid-email': 'Please enter a valid email address.',
        'auth/weak-password': 'Password is not strong enough.',
        'default': 'Something went wrong. Please try again.'
    }

    store.dispatch('notification/setNotification', {
        type: 'error',
        message: messages[code as keyof typeof messages] || messages['default' as keyof typeof messages]
    })
};

const notifyFailResetPassword = (code: string) => {
    const messages = {
        'auth/invalid-email': 'Please enter a valid email address.',
        'auth/user-not-found': 'No account found with this email address.',
        'default': 'Something went wrong. Please try again.'
    }

    store.dispatch('notification/setNotification', {
        type: 'error',
        message: messages[code as keyof typeof messages] || messages['default' as keyof typeof messages]
    })
};

const hideNotification = () => {
    store.dispatch('notification/setNotification', {
        type: null,
        message: null
    });
};

const addToCart = () => {
    store.dispatch('notification/setNotification', {
        type: 'success',
        message: 'Added Successfully.'
    })
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$notifySuccess = notifySuccess;
        app.config.globalProperties.$notifySuccessLogin = notifySuccessLogin;
        app.config.globalProperties.$notifySuccessPasswordResetLink = notifySuccessPasswordResetLink;
        app.config.globalProperties.$notifySuccessRegister = notifySuccessRegister;
        app.config.globalProperties.$notifyError = notifyError;
        app.config.globalProperties.$notifyFailLogin = notifyFailLogin;
        app.config.globalProperties.$notifyFailResetPassword = notifyFailResetPassword;
        app.config.globalProperties.$notifyFailRegister = notifyFailRegister;
        app.config.globalProperties.$hideNotification = hideNotification;
        app.config.globalProperties.$addToCart = addToCart;
        app.component('notification', Notification);
    }
}
