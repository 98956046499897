import axios from "axios";
import store from "@/store";

const axiosInstance = axios.create({
    baseURL: "https://zalatimoprod.rhinosoft.io/api"
});

axiosInstance.interceptors.request.use(
    (config: any) => {
        if (!config) {
            config = {};
        }
        if (!config.headers) {
            config.headers = {};
        }
        config.headers["idToken"] = store.getters["auth/idToken"];
        config.headers["international"] = localStorage.international;
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Credentials"] = "true";
        config.headers["Access-Control-Allow-Methods"] =
            "GET,HEAD,OPTIONS,POST,PUT";
        if (localStorage.currency) {
            config.headers["currency"] = localStorage.currency;
        }
        //header
        config.headers["language"] = store.getters["localization/locale"];
        //get locale from store


        return config;
    },

    (error: any) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
